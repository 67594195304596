import { default as Origin, TextFieldProps } from '@atlaskit/textfield';
import styled from 'styled-components';
import Tokens from './Tokens';
import { ErrorMessage, HelperMessage, Label } from './Form';
import React, { useState } from 'react';
import MathHelper from '../../helpers/MathHelper';

export type iTextField = TextFieldProps & {
  label?: string;
  errorMsg?: React.ReactNode;
  helperMsg?: React.ReactNode;
  isRequired?: boolean;
};

const Wrapper = styled.div`
  label {
    .RequiredAsterisk {
      color: ${Tokens('color.text.danger')};
      font-family: ${Tokens('font.body.small')};
      -webkit-padding-start: ${Tokens('space.050', '2px')};
      padding-inline-start: ${Tokens('space.050', '2px')};
    }
  }
`;

const TextField = ({
  label,
  errorMsg,
  isRequired,
  helperMsg,
  testId,
  ...props
}: iTextField) => {
  const [htmlId] = useState(
    `textField-${Math.ceil(MathHelper.mul(Math.random(), 10000000))}`,
  );
  const getLabel = () => {
    if (!label) {
      return null;
    }

    return (
      <Label htmlFor={htmlId} testId={`${testId}-label`}>
        {label}
        {isRequired && (
          <span
            className={'RequiredAsterisk'}
            data-testid={`${testId}-label-required`}
          >
            *
          </span>
        )}
      </Label>
    );
  };

  const getErrorMsg = () => {
    if (!errorMsg) {
      return null;
    }

    if (Array.isArray(errorMsg)) {
      return (
        <ErrorMessage testId={`${testId}-errorMsg`}>
          {errorMsg.map((msg, index) => (
            <span key={index}>{msg}</span>
          ))}
        </ErrorMessage>
      );
    }

    return (
      <ErrorMessage testId={`${testId}-errorMsg`}>{errorMsg}</ErrorMessage>
    );
  };

  const getHelperMsg = () => {
    if (!helperMsg) {
      return null;
    }

    return (
      <HelperMessage testId={`${testId}-helperMsg`}>{helperMsg}</HelperMessage>
    );
  };

  return (
    <Wrapper data-testid={testId}>
      {getLabel()}
      <Origin {...props} id={htmlId} testId={`${testId}-textField`} />
      {getHelperMsg()}
      {getErrorMsg()}
    </Wrapper>
  );
};

export default TextField;
