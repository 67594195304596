import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import GasTypeService from '../../services/system/GasTypeService';
import iGasType from '../../types/system/iGasType';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';

type iGasTypeList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const GasTypeList = ({ allowDelete, headerProps }: iGasTypeList) => {
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iGasType>({
      sort: `name:ASC`,
      getFn: (params) =>
        GasTypeService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (GasType?: iGasType) => {
    return renderEntityEditPopBtn<iGasType>({
      editingEntity: GasType,
      entityName: 'GasType',
      createFn: (data) => GasTypeService.create(data),
      updateFn: (id, data) => GasTypeService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'gasType-name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'gasType-description',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iGasType>[] => [
    {
      key: 'name',
      header: 'Name',
      cell: ({ data }: iCellParams<iGasType>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'description',
      header: 'Description',
      cell: ({ data }: iCellParams<iGasType>) => `${data.description || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iGasType>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iGasType>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => GasTypeService.deactivate(data.id),
                    getDisplayName: (GasType) => GasType.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'GasType-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default GasTypeList;
