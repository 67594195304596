import PersonIcon from '@atlaskit/icon/glyph/person';
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled';
import SendIcon from '@atlaskit/icon/glyph/send';
import HomeIcon from '@atlaskit/icon/glyph/home';
import HomeCircleIcon from '@atlaskit/icon/glyph/home-circle';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import SignInIcon from '@atlaskit/icon/glyph/sign-in';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import OfficeBuildingFilledIcon from '@atlaskit/icon/glyph/office-building-filled';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import AddIcon from '@atlaskit/icon/glyph/add';
import BillingIcon from '@atlaskit/icon/glyph/billing';
import MediaServicesOvalIcon from '@atlaskit/icon/glyph/media-services/oval';

const Icons = {
  HomeCircleIcon,
  HomeIcon,
  PersonIcon,
  LockFilledIcon,
  SendIcon,
  CrossIcon,
  SignInIcon,
  SignOutIcon,
  SettingsIcon,
  OfficeBuildingFilledIcon,
  PeopleGroupIcon,
  TrashIcon,
  RefreshIcon,
  AddIcon,
  BillingIcon,
  MediaServicesOvalIcon,
};

export default Icons;
