import {
  Content as OriginContent,
  Main as OriginMain,
  PageLayout as Origin,
  TopNavigation as OriginTopNavigation,
  LeftSidebar as OriginLeftSidebar,
} from '@atlaskit/page-layout';

export const PageLayout = Origin;
export const Content = OriginContent;
export const Main = OriginMain;
export const TopNavigation = OriginTopNavigation;
export const LeftSidebar = OriginLeftSidebar;

export default PageLayout;
