import Image from '../frameWork/Image';

type iLogo = {
  width?: number;
  height?: number;
  className?: string;
};
const Logo = ({ width, height, className }: iLogo) => {
  return (
    <Image
      src={'/images/logo.png'}
      alt="logo"
      className={`logo ${className || ''}`}
      width={width}
      height={height}
    />
  );
};

export default Logo;
