import iUser from '../types/system/iUser';

const getFullName = (user: iUser) => {
  return [`${user.firstName || ''}`.trim(), `${user.lastName || ''}`.trim()]
    .join(' ')
    .trim();
};

const UserHelper = {
  getFullName,
};

export default UserHelper;
