import {
  default as Origin,
  SelectProps,
  AsyncSelect as OriginAsyncSelect,
  OptionType,
} from '@atlaskit/select';

export type iSelect<T, D extends boolean = false> = SelectProps<T, D>;

export type iOption = OptionType;

const Select = Origin;
export const AsyncSelect = OriginAsyncSelect;

export const convertSameValueToOption = (value: string | number): iOption => {
  return {
    value: value,
    label: `${value}`,
  };
};

export default Select;
