import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  URL_BACKEND,
  URL_LOGIN,
  URL_MY_PROFILE,
  URL_SETTINGS,
  URL_SETTINGS_BANKS,
  URL_SETTINGS_COMPANY_PROFILE,
  URL_SETTINGS_GAS_TYPES,
  URL_SETTINGS_USERS,
} from './helpers/UrlMap';
import store from './redux/makeReduxStore';
import LoginPage from './pages/auth/LoginPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/auth/ProfilePage';
import CompanyProfilePage from './pages/settings/CompanyProfilePage';
import UsersPage from './pages/settings/UsersPage';
import 'react-toastify/dist/ReactToastify.css';
import Toaster from './components/common/Toaster';
import BanksPage from './pages/settings/BanksPage';
import GasTypesPage from './pages/settings/GasTypesPage';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        {/*<Navbar />*/}
        <Routes>
          <Route path={URL_LOGIN} element={<LoginPage />} />

          <Route path={URL_BACKEND} element={<HomePage />} />
          <Route path={URL_MY_PROFILE} element={<ProfilePage />} />

          <Route path={URL_SETTINGS} element={<CompanyProfilePage />} />
          <Route
            path={URL_SETTINGS_COMPANY_PROFILE}
            element={<CompanyProfilePage />}
          />
          <Route path={URL_SETTINGS_USERS} element={<UsersPage />} />
          <Route path={URL_SETTINGS_BANKS} element={<BanksPage />} />
          <Route path={URL_SETTINGS_GAS_TYPES} element={<GasTypesPage />} />

          <Route path={'/'} element={<HomePage />} />
          <Route path="*" element={<h1>not found</h1>} />
        </Routes>
      </Router>
      {Toaster.getContainer()}
    </Provider>
  );
};

export default App;
