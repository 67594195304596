import React, { useCallback } from 'react';
import Button, { ButtonProps } from '../frameWork/Button';
import PopupModal, { iPopupModal } from './PopupModal';

export type iSetShowingModalFn = (showing: boolean) => void;
export type iPopupBtn = Omit<ButtonProps, 'children' | 'onClick'> & {
  renderBtn?: (onClick: () => void) => React.ReactNode;
  btnTxt?: React.ReactNode;
  modalProps?: (
    setShowingModal: iSetShowingModalFn,
    isDisabled: boolean,
  ) => Omit<iPopupModal, 'titleId' | 'isOpen' | 'children'>;
  isDisabled?: boolean;
  titleId: string;
};

const PopupBtn = ({
  titleId,
  renderBtn,
  btnTxt = '',
  modalProps,
  isDisabled = false,
  ...props
}: iPopupBtn) => {
  const [showingPopup, setShowingPopup] = React.useState(false);

  const showOrHideModal = useCallback(
    (param: boolean) => setShowingPopup(param),
    [],
  );

  const getBtn = () => {
    if (!renderBtn) {
      return (
        <Button {...props} onClick={() => showOrHideModal(true)}>
          {btnTxt}
        </Button>
      );
    }
    return renderBtn(() => showOrHideModal(!showingPopup));
  };

  const handleClose = () => {
    if (isDisabled === true) {
      return;
    }

    showOrHideModal(false);
  };

  return (
    <>
      {getBtn()}
      <PopupModal
        onClose={handleClose}
        titleId={titleId}
        {...(modalProps && modalProps(showOrHideModal, isDisabled))}
        isOpen={showingPopup}
      />
    </>
  );
};

export default PopupBtn;
