import { default as Origin, DynamicTableProps } from '@atlaskit/dynamic-table';

import {
  RowCellType,
  RowType,
  HeadCellType,
  HeadType,
} from '@atlaskit/dynamic-table/types';
import styled from 'styled-components';
import Pagination from './Pagination';
import * as _ from 'lodash';
import MathHelper from '../../helpers/MathHelper';
import PageSizeSelector from '../pagination/PageSizeSelector';
import { iOption } from './Select';
import PaginationDetails, {
  iPaginationDetail,
} from '../pagination/PaginationDetails';
import React from 'react';
import Tokens from './Tokens';

export type iRowCellType = RowCellType;
export type iRowType = RowType;
export type iHeadType = HeadType;
export type iHeadCellType = HeadCellType;

export type iDynamicTable = DynamicTableProps & {
  testId?: string;
  className?: string;
  totalPages?: number;
  paginationDetails?: iPaginationDetail;
  onPageSizeChange?: (pageSize: number) => void;
  bottomLeft?: React.ReactNode;
  topRow?: React.ReactNode;
};
const Wrapper = styled.div`
  .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page-sizer {
    > [class$='control'] {
      min-height: auto;
      //background-color: blue;
      [class$='indicatorContainer'] {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  .data-pagination {
    font-size: ${Tokens('font.size.100')};
    color: ${Tokens('color.background.accent.gray.bolder.pressed')};
  }
    
  table > caption,
  [class$='Caption'] {
    margin-top: 0px;
  }
}
`;
const DynamicTable = ({
  testId = 'DynamicTable',
  className,
  totalPages,
  page,
  onSetPage,
  onPageSizeChange,
  rowsPerPage,
  bottomLeft,
  caption,
  paginationDetails,
  topRow,
  ...props
}: iDynamicTable) => {
  const tPages = totalPages || 0;
  const currentPage = page || 1;

  const getPagination = () => {
    if (
      tPages <= 0 ||
      tPages < currentPage ||
      (tPages === currentPage && tPages === 1)
    ) {
      return null;
    }

    return (
      <Pagination
        pages={_.range(1, MathHelper.add(tPages, 1))}
        selectedIndex={MathHelper.sub(currentPage, 1)}
        nextLabel="Next"
        label="Page"
        pageLabel="Page"
        previousLabel="Previous"
        onChange={(event, page) => {
          onSetPage && onSetPage(page);
        }}
      />
    );
  };

  const getPageSizeSelector = () => {
    if (!onPageSizeChange) {
      return null;
    }

    return (
      <PageSizeSelector
        className={'page-sizer'}
        value={rowsPerPage}
        onChange={(newPageSize: iOption) => {
          onPageSizeChange && onPageSizeChange(Number(newPageSize.value));
        }}
      />
    );
  };

  const getBottomRow = () => {
    const pagination = getPagination();
    const pageSizeSelector = getPageSizeSelector();
    const bottomLeftDiv = bottomLeft || null;

    if (!pagination && !pageSizeSelector && !bottomLeftDiv) {
      return null;
    }

    return (
      <div className={'bottom-row'}>
        {bottomLeftDiv || <div />} {pagination || <div />}{' '}
        {pageSizeSelector || <div />}
      </div>
    );
  };

  const getTopRow = () => {
    if (topRow) {
      return topRow;
    }
    const paginationDetailsDiv = paginationDetails ? (
      <PaginationDetails
        {...paginationDetails}
        className={'data-pagination'}
        testId={`dynamic-tbl-p-details-${testId}`}
      />
    ) : null;
    if (paginationDetailsDiv) {
      return <div>{paginationDetailsDiv}</div>;
    }
    return null;
  };

  const getCaption = () => {
    const topRow = getTopRow();
    if (!topRow) {
      return caption;
    }
    return (
      <>
        {caption}
        {topRow}
      </>
    );
  };

  return (
    <Wrapper
      className={`dynamic-tbl-wrapper ${className || ''}`}
      data-testid={testId}
    >
      <Origin
        {...props}
        onSetPage={onSetPage}
        caption={getCaption()}
        testId={`dynamic-tbl-${testId}`}
      />
      {getBottomRow()}
    </Wrapper>
  );
};
export default DynamicTable;
