import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import React, { useState } from 'react';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import { iConfigParams } from '../../services/AppService';
import { iErrorMap } from './FormError';
import iBaseType from '../../types/iBaseType';
import EntityEditPanel, { iEntityFormField } from './EntityEditPanel';

type iEditPopupBtn<T extends iBaseType> = Omit<iPopupBtn, 'titleId'> & {
  entity?: T;
  entityName: string;
  onSaved?: (saved: T) => void;
  createFn: (data: iConfigParams) => Promise<T>;
  updateFn: (id: string, data: iConfigParams) => Promise<T>;
  validateFields?: (data: iConfigParams) => iErrorMap;
  getPopupTitle?: (entity?: T) => string;
  getFormFields: (data: {
    entity?: T | null;
    isDisabled?: boolean;
  }) => iEntityFormField[];
};

const EntityEditPopupBtn = <T extends iBaseType>({
  entityName,
  entity,
  onSaved,
  createFn,
  updateFn,
  validateFields,
  getPopupTitle,
  getFormFields,
  ...props
}: iEditPopupBtn<T>) => {
  const [editingData, setEditingData] = useState<iConfigParams | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const preSave = () => {
    const data: iConfigParams = {
      ...(entity || {}),
      ...(editingData || {}),
    };

    const fields = getFormFields({
      entity,
      isDisabled: isSaving === true,
    });

    const errors: iErrorMap = validateFields
      ? validateFields(data)
      : fields.reduce((map: iErrorMap, field: iEntityFormField) => {
          const fieldErrors = [];
          if (
            field.isRequired === true &&
            `${field.fieldName in data ? data[field.fieldName] : ''}`.trim() ===
              ''
          ) {
            fieldErrors.push(`${field.fieldName.toUpperCase()} is required`);
          }
          if (field.isValid) {
            const { isValid, errMessages } = field.isValid(
              field.fieldName in data ? data[field.fieldName] : null,
            );
            if (isValid !== true) {
              errMessages.forEach((errMessage) => {
                fieldErrors.push(`${errMessage}`.trim());
              });
            }
          }

          const filteredErrors = fieldErrors.filter(
            (errMsg) => `${errMsg}`.trim() !== '',
          );
          if (filteredErrors.length <= 0) {
            return map;
          }

          return {
            ...map,
            [field.fieldName]: filteredErrors,
          };
        }, {});

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setShowingModal: iSetShowingModalFn) => {
    if (
      !editingData ||
      Object.keys(editingData || {}).length <= 0 ||
      preSave() !== true
    ) {
      return;
    }
    setIsSaving(true);
    const entityId = `${entity?.id || ''}`.trim();

    const saveFnc = () =>
      entityId === '' ? createFn(editingData) : updateFn(entityId, editingData);

    saveFnc()
      .then((resp) => {
        setIsSaving(false);
        setShowingModal(false);
        setEditingData({});
        Toaster.showToast(
          entityId === '' ? `${entityName} Created` : `${entityName} Updated`,
          TOAST_TYPE_SUCCESS,
        );
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={`Edit-popup-${entity?.id || 'new-entity'}`}
      isDisabled={isSaving}
      modalProps={(setShowingModal) => ({
        showCloseBtn: isSaving !== true,
        shouldScrollInViewport: true,
        width: '760px',
        title: getPopupTitle
          ? getPopupTitle(entity)
          : entity
            ? `Editing ${entityName}:`
            : `Creating a ${entityName}:`,
        body: (
          <div className={'margin-auto'}>
            <EntityEditPanel
              isDisabled={isSaving}
              className={'editor'}
              getFormFields={getFormFields}
              useAsForm={{
                onFieldChange: (fieldName, value) => {
                  setEditingData({
                    ...(editingData || {}),
                    [fieldName]: value,
                  });
                },
                errorMap: errorMap,
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              entity={
                entity ? { ...entity, ...(editingData || {}) } : editingData
              }
            />
          </div>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => {
              setErrorMap({});
              setEditingData(null);
              setShowingModal(false);
            },
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Save',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setShowingModal),
          },
        }),
      })}
    />
  );
};

export default EntityEditPopupBtn;
