import ProtectedPage from '../layouts/ProtectedPage';

const HomePage = () => {
  return (
    <ProtectedPage>
      <h1>Home</h1>
    </ProtectedPage>
  );
};

export default HomePage;
