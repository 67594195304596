import AppService, { iConfigParams } from '../AppService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import iPaginatedResult from '../../types/iPaginatedResult';
import iBaseType from '../../types/iBaseType';

const getPaginatedAll =
  <T extends iBaseType>(endPoint: string) =>
  async (
    params?: iConfigParams,
    config: AxiosRequestConfig = {},
  ): Promise<iPaginatedResult<T>> => {
    return AppService.get(endPoint, params, config).then(
      ({ data }: AxiosResponse) => data,
    );
  };

const getAll =
  <T extends iBaseType>(endPoint: string) =>
  async (
    params?: iConfigParams,
    config: AxiosRequestConfig = {},
  ): Promise<T[]> => {
    return AppService.get(endPoint, params, config).then(
      ({ data }: AxiosResponse) => data,
    );
  };

const getById =
  <T extends iBaseType>(endPoint: string) =>
  async (
    id: string,
    params?: iConfigParams,
    config: AxiosRequestConfig = {},
  ): Promise<T> => {
    return AppService.get(`${endPoint}/${id}`, params, config).then(
      ({ data }: AxiosResponse) => data,
    );
  };

const create =
  <T extends iBaseType>(endPoint: string) =>
  async (
    data: iConfigParams = {},
    config: AxiosRequestConfig = {},
  ): Promise<T> => {
    return AppService.post(endPoint, data, config).then(
      ({ data }: AxiosResponse) => data,
    );
  };

const updateById =
  <T extends iBaseType>(endPoint: string) =>
  async (
    id: string,
    data: iConfigParams = {},
    config: AxiosRequestConfig = {},
  ): Promise<T> => {
    return AppService.put(`${endPoint}/${id}`, data, config).then(
      ({ data }: AxiosResponse) => data,
    );
  };

const deactivateById =
  <T extends iBaseType>(endPoint: string) =>
  async (
    id: string,
    data: iConfigParams = {},
    config: AxiosRequestConfig = {},
  ): Promise<T> => {
    return AppService.delete(`${endPoint}/${id}`, data, config).then(
      ({ data }: AxiosResponse) => data,
    );
  };

const ServiceHelper = {
  getPaginatedAll,
  getAll,
  getById,
  create,
  updateById,
  deactivateById,
};

export default ServiceHelper;
