import SideNavigation, {
  NavigationHeader,
  NavigationContent,
  Section,
  LinkItem,
  HeadingItem,
} from '../frameWork/SideNavigation';
import Avatar, { AvatarItem } from '../frameWork/Avatar';
import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { useLocation } from 'react-router-dom';
import {
  URL_SETTINGS,
  URL_SETTINGS_BANKS,
  URL_SETTINGS_COMPANY_PROFILE,
  URL_SETTINGS_GAS_TYPES,
  URL_SETTINGS_USERS,
} from '../../helpers/UrlMap';
import Icons from '../frameWork/Icons';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../redux/makeReduxStore';

const Wrapper = styled.div`
  height: 100%;
  background-color: ${Tokens('color.background.accent.gray.subtlest')};
  [data-testid='sidebar-avatar'] {
    img {
      background-color: ${Tokens('color.background.accent.gray.subtlest')};
      object-fit: contain;
    }
  }
`;
const testId = 'side-nav';
const SettingsSideBar = () => {
  const { pathname } = useLocation();

  // const { user } = useSelector((s: RootState) => s.auth);
  return (
    <Wrapper className={'side-nav-wrapper'}>
      <SideNavigation label={'settings'} testId={testId}>
        <NavigationHeader>
          <AvatarItem
            avatar={
              <Avatar
                size={'medium'}
                src={'/images/logo.png'}
                testId={'sidebar-avatar'}
                appearance={'square'}
              />
            }
            primaryText={
              <h4 data-testid={`${testId}-companyName`}>Company Name</h4>
            }
            secondaryText={'company'}
            testId={`${testId}-sidebar-logo`}
          />
        </NavigationHeader>
        <NavigationContent testId="navigation-content" className="nav-content">
          <Section isList>
            {[
              {
                key: 'company-profile',
                name: 'Company Profile',
                icon: (
                  <Icons.OfficeBuildingFilledIcon label={'company profile'} />
                ),
                url: URL_SETTINGS_COMPANY_PROFILE,
                isSelected:
                  pathname === URL_SETTINGS_COMPANY_PROFILE ||
                  pathname === URL_SETTINGS,
              },
              {
                key: 'users',
                name: 'Users',
                icon: <Icons.PeopleGroupIcon label={'Users'} />,
                url: URL_SETTINGS_USERS,
                isSelected: pathname === URL_SETTINGS_USERS,
              },
            ].map((item) => (
              <LinkItem
                key={item.name}
                testId={item.name}
                iconBefore={item.icon}
                isSelected={item.isSelected}
                href={item.url}
              >
                {item.name}
              </LinkItem>
            ))}
          </Section>

          <Section isList>
            <HeadingItem>Settings</HeadingItem>
            {[
              {
                key: 'banks',
                name: 'Banks',
                icon: <Icons.BillingIcon label={'Banks'} />,
                url: URL_SETTINGS_BANKS,
                isSelected: pathname === URL_SETTINGS_BANKS,
              },
              {
                key: 'gasTypes',
                name: 'Gas Types',
                icon: <Icons.MediaServicesOvalIcon label={'Gas Types'} />,
                url: URL_SETTINGS_GAS_TYPES,
                isSelected: pathname === URL_SETTINGS_GAS_TYPES,
              },
            ].map((item) => (
              <LinkItem
                key={item.name}
                testId={item.name}
                iconBefore={item.icon}
                isSelected={item.isSelected}
                href={item.url}
              >
                {item.name}
              </LinkItem>
            ))}
          </Section>
        </NavigationContent>
      </SideNavigation>
    </Wrapper>
  );
};

export default SettingsSideBar;
