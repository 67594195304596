import ProtectedRoute from './ProtectedRoute';
import React from 'react';
import {
  Content,
  Main,
  PageLayout,
  TopNavigation,
  LeftSidebar,
} from '../components/frameWork/PageLayout';
import TopNav from '../components/layout/TopNav';
import styled from 'styled-components';
import Tokens from '../components/frameWork/Tokens';
import { LeftSidebarProps } from '@atlaskit/page-layout/dist/types/common/types';

const PageWrapper = styled.div`
  height: 100%;
  [data-testid='content'] > [data-testid='main'] {
    padding-left: ${Tokens('space.200')};
    padding-right: ${Tokens('space.200')};
  }
`;

type iProtectedPage = {
  children: React.ReactNode;
  leftSideBar?: React.ReactNode;
  leftSideBarProps?: Omit<LeftSidebarProps, 'children'>;
};
const ProtectedPage = ({
  children,
  leftSideBar,
  leftSideBarProps,
}: iProtectedPage) => {
  const getLeftSideBar = () => {
    if (!leftSideBar) {
      return null;
    }

    return (
      <LeftSidebar
        id={'page-left-menu'}
        {...leftSideBarProps}
        testId={'left-side-bar'}
      >
        {leftSideBar}
      </LeftSidebar>
    );
  };

  return (
    <ProtectedRoute>
      <PageWrapper data-testid="protected-page-wrapper">
        <PageLayout testId={'page-layout'}>
          <TopNavigation isFixed={false} testId={'top-nav'}>
            <TopNav />
          </TopNavigation>
          <Content testId="content">
            {getLeftSideBar()}
            <Main testId={'main'}>{children}</Main>
          </Content>
        </PageLayout>
      </PageWrapper>
    </ProtectedRoute>
  );
};

export default ProtectedPage;
