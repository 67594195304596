import Text from '../frameWork/Text';

export type iErrorMap = { [key: string]: string[] };

type iFormError = {
  error: iErrorMap;
  fieldName: string;
};
const FormError = ({ error, fieldName }: iFormError) => {
  if (!(fieldName in error)) {
    return null;
  }
  return (
    <Text color={'color.text.danger'} size={'small'}>
      {error[fieldName]}
    </Text>
  );
};

export default FormError;
