import Navigation, { PrimaryButton } from '../frameWork/Navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';
import Logo from '../common/Logo';
import { URL_BACKEND, URL_SETTINGS } from '../../helpers/UrlMap';
import UserAvatarPopup from '../user/UserAvatarPopup';
import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { LinkIconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';

const LogoWrapper = styled.a`
  cursor: pointer;
  display: inline-block;
  margin-right: ${Tokens('space.200')};
`;
const TopNav = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const testId = 'topNav';
  return (
    <Navigation
      testId={testId}
      label={'TopNav'}
      renderAppSwitcher={() => (
        <LogoWrapper href={URL_BACKEND} data-testid={`${testId}-logoWrapper`}>
          <Logo width={100} />
        </LogoWrapper>
      )}
      primaryItems={[
        <PrimaryButton href={URL_BACKEND} key={1}>
          Home
        </PrimaryButton>,
      ]}
      renderProductHome={() => null}
      renderHelp={() => null}
      renderSettings={() => (
        <LinkIconButton
          testId={`${testId}-settings`}
          icon={Icons.SettingsIcon}
          label={'settings'}
          href={URL_SETTINGS}
          shape={'circle'}
          appearance={'subtle'}
        />
      )}
      renderSignIn={() => null}
      renderSearch={() => null}
      renderNotifications={() => null}
      renderProfile={() => <UserAvatarPopup user={user} />}
      renderCreate={() => null}
    />
  );
};

export default TopNav;
