import {
  iHeadCellType,
  iHeadType,
  iRowCellType,
  iRowType,
} from '../components/frameWork/DynamicTable';
import * as _ from 'lodash';
import React from 'react';
import ModifyBy from '../components/common/ModifyBy';
import iUser from '../types/system/iUser';

type iDynamicBaseType = { id: string };

export type iHeaderParams<T extends iDynamicBaseType> = {
  col: iTableColumn<T>;
};

export type iCellParams<T extends iDynamicBaseType> = {
  col: iTableColumn<T>;
  data: T;
};

export type iTableColumn<T extends iDynamicBaseType> = Omit<
  iHeadCellType,
  'content'
> & {
  key: string;
  header: string | ((params: iHeaderParams<T>) => string);
  cell: string | ((params: iCellParams<T>) => React.ReactNode);
};

const getHead = (data: iHeadCellType[], extra = {}): iHeadType => {
  return {
    cells: data,
    ...extra,
  };
};

const getRows = <T extends iDynamicBaseType>(
  data: T[],
  eachRow: (row: T) => iRowCellType[],
  extra = {},
): iRowType[] => {
  return data.map((row: T) => ({
    key: row.id,
    cells: eachRow(row),
    ...extra,
  }));
};

const handleNullException = (
  value: string | null | undefined,
  showAsNumber = false,
) => {
  if (_.isNull(value) || _.isUndefined(value)) {
    return showAsNumber ? '0' : '';
  }
  return value;
};

const getCreatedAndUpdatedColumns = <
  T extends {
    id: string;
    CreatedBy?: iUser;
    createdAt: string;
    UpdatedBy?: iUser;
    updatedAt: string;
  },
>(): iTableColumn<T>[] => {
  return [
    {
      key: 'created',
      header: 'Created',
      cell: ({ data }) => <ModifyBy by={data.CreatedBy} at={data.createdAt} />,
    },
    {
      key: 'updated',
      header: 'Updated',
      cell: ({ data }) => <ModifyBy by={data.UpdatedBy} at={data.updatedAt} />,
    },
  ];
};

const getHeadAndRowsFromColumns = <T extends iDynamicBaseType>(
  cols: iTableColumn<T>[],
  data: T[],
): [iHeadType, iRowType[]] => {
  const head = getHead(
    cols.map((col) => ({
      key: col.key,
      isSortable: col.isSortable,
      content:
        typeof col.header === 'function'
          ? col.header({ col })
          : `${col.header || ''}`,
    })),
  );
  const rows = getRows<T>(data, (row) => {
    return cols.map((col) => ({
      key: col.key,
      content:
        typeof col.cell === 'function'
          ? col.cell({ col, data: row })
          : `${col.cell || ''}`,
    }));
  });
  return [head, rows];
};

const DynamicTableHelper = {
  getHead,
  getRows,
  handleNullException,
  getHeadAndRowsFromColumns,
  getCreatedAndUpdatedColumns,
};

export default DynamicTableHelper;
