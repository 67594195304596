import TextField from '../frameWork/TextField';
import styled from 'styled-components';
import { iErrorMap } from '../form/FormError';
import iBaseType from '../../types/iBaseType';

const Wrapper = styled.div`
  .field-wrapper {
    margin-bottom: 0.4rem;
  }
`;

export type iEntityFormField = {
  fieldName: string;
  label: string;
  isDisabled?: boolean;
  value: string;
  isRequired?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isValid?: (value: any) => { isValid: boolean; errMessages: string[] };
  testId?: string;
};

type iEntityEditPanel<T extends iBaseType> = {
  entity?: T | null;
  className?: string;
  testId?: string;
  isDisabled?: boolean;
  getFormFields: (data: {
    entity?: T | null;
    isDisabled?: boolean;
  }) => iEntityFormField[];
  useAsForm: {
    onFieldChange: (fieldName: string, newValue: string) => void;
    errorMap: iErrorMap;
  };
};

const EntityEditPanel = <T extends iBaseType>({
  entity,
  className,
  testId,
  useAsForm,
  getFormFields,
  isDisabled = false,
}: iEntityEditPanel<T>) => {
  return (
    <Wrapper
      className={`entity-edit-panel ${className || ''}`}
      data-testid={testId}
    >
      {getFormFields({ entity, isDisabled }).map((row: iEntityFormField) => {
        return (
          <div
            className={`field-wrapper ${row.fieldName || ''}`}
            key={row.fieldName}
          >
            <TextField
              testId={row.testId || ''}
              isRequired={row.isRequired}
              isDisabled={row.isDisabled}
              isInvalid={row.fieldName in (useAsForm.errorMap || {})}
              errorMsg={useAsForm.errorMap[row.fieldName]}
              label={row.label}
              value={row.value}
              placeholder={row.label}
              onChange={(event) =>
                useAsForm.onFieldChange(
                  row.fieldName,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  event.target.value || '',
                )
              }
            />
          </div>
        );
      })}
    </Wrapper>
  );
};

export default EntityEditPanel;
