import { default as Origin, InlineEditProps } from '@atlaskit/inline-edit';
import TextField from './TextField';
import type { default as React } from 'react';
import { ExtendedFieldProps } from '@atlaskit/inline-edit/dist/types/types';
import styled from 'styled-components';
import Tokens from './Tokens';

const ViewWrapper = styled.div`
  display: flex;
  max-width: 100%;
  padding-block: ${Tokens('space.100')};
  padding-inline: ${Tokens('space.075')};
  min-width: 100px;
  line-height: 20px;
  cursor: pointer;
  background-color: ${Tokens('color.background.accent.gray.subtlest')};
  border-radius: 4px;

  &.default-view {
    color: ${Tokens('color.background.accent.gray.subtlest.hovered')};
  }

  &.disabled-view {
    background-color: transparent !important;
    color: ${Tokens('color.background.accent.gray.subtlest.pressed')};
    cursor: not-allowed;
  }
`;
export type iInlineEdit = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Omit<Omit<InlineEditProps<any>, 'editView'>, 'readView'>,
  'defaultValue'
> & {
  isDisabled?: boolean;
  value?: string;
  defaultValue?: string;
  placeHolder?: string;
  editView?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fieldProps: ExtendedFieldProps<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: React.RefObject<any>,
  ) => React.ReactNode;
  readView?: () => React.ReactNode;
};
const InlineEdit = ({
  isDisabled = false,
  editView,
  readView,
  value,
  placeHolder,
  defaultValue,
  isEditing,
  testId,
  ...props
}: iInlineEdit) => {
  const getEditView = () => {
    if (editView) {
      return editView;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,react/display-name
    return ({ errorMessage, defaultValue, ...fieldProps }) => (
      <TextField
        {...fieldProps}
        value={value || defaultValue}
        testId={`edit-view-${testId}`}
        placeholder={placeHolder}
        autoFocus
      />
    );
  };

  const getReadView = () => {
    if (readView) {
      return readView;
    }

    // eslint-disable-next-line react/display-name
    return () => (
      <ViewWrapper
        data-testid={`read-view-${testId}`}
        className={`view-wrapper ${
          `${value || defaultValue || ''}`.trim() === '' ? 'default-view' : ''
        } ${isDisabled === true ? 'disabled-view ' : ''}`}
      >
        {value || placeHolder || defaultValue || 'Please input some value...'}
      </ViewWrapper>
    );
  };

  const getIsEditing = () => {
    if (isEditing !== undefined) {
      return isEditing;
    }

    return isDisabled ? false : isEditing;
  };

  return (
    <Origin
      {...props}
      testId={testId}
      isEditing={getIsEditing()}
      readView={getReadView()}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      editView={getEditView()}
    />
  );
};

export default InlineEdit;
