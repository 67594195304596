export const URL_LOGIN = '/login';

export const URL_BACKEND = '/b';
export const URL_MY_PROFILE = `${URL_BACKEND}/me`;

export const URL_SETTINGS = `${URL_BACKEND}/settings`;
export const URL_SETTINGS_COMPANY_PROFILE = `${URL_SETTINGS}/company`;
export const URL_SETTINGS_USERS = `${URL_SETTINGS}/users`;
export const URL_SETTINGS_BANKS = `${URL_SETTINGS}/banks`;
export const URL_SETTINGS_GAS_TYPES = `${URL_SETTINGS}/gasTypes`;
