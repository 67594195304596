import SettingsPage from '../../layouts/SettingsPage';
import GasTypeList from '../../components/settings/GasTypeList';

const GasTypesPage = () => {
  return (
    <SettingsPage title={'GasTypes'}>
      {(headerProps) => <GasTypeList allowDelete headerProps={headerProps} />}
    </SettingsPage>
  );
};

export default GasTypesPage;
