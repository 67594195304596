import iUser from '../../types/system/iUser';
import styled from 'styled-components';
import UserHelper from '../../helpers/UserHelper';
import moment from 'moment';
import Tokens from '../frameWork/Tokens';

export type iModifyBy = {
  by?: iUser;
  at?: string;
  testId?: string;
};
const Wrapper = styled.div`
  .at {
    display: flex;
    gap: ${Tokens('space.050')};
  }
`;
const ModifyBy = ({ by, at, testId }: iModifyBy) => {
  if (!by && !at) {
    return null;
  }
  return (
    <Wrapper data-testid={`modify-by-${testId || ''}`}>
      {by && (
        <div className={'user'} data-testid={`modify-by-${testId || ''}-user`}>
          {UserHelper.getFullName(by)}
        </div>
      )}
      {`${at || ''}`.trim() !== '' && (
        <div className={'at'} data-testid={`modify-by-${testId || ''}-at`}>
          <span>@</span>
          <span>{moment(at).format('lll')}</span>
        </div>
      )}
    </Wrapper>
  );
};

export default ModifyBy;
